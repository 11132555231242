.globalLayoutHeight {
  height: 100%;
}

.MuiBreadcrumbs-separator {
  font-size: 24px;
}

.cursorPointer {
  cursor: pointer;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.globalTableLayoutStyle {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  border-radius: 12px !important;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.13) !important;
  border: 1px solid #eff0f5 !important;
}

.globalTableContainerAlign {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading {
  height: 32px;
  color: #2e1c41 !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  justify-content: center;
}

.subheading {
  height: 32px;
  color: #2e1c41 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  justify-content: center;
}

.tableheading {
  color: #2e1c41 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 30px !important;
}

.tablebody {
  color: #2e1c41 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 30px !important;
}
