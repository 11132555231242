.subheadingWrap {
  max-height: 400px !important;
  overflow-y: auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.designationList {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #2e1c41 !important;
  align-items: center !important;
}
.designationListWrap {
  max-height: 25rem !important;
  overflow-y: auto !important;
  background-color: #ffffff;
  border-radius: 12px !important;
}
.saveBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.selectAllWrap {
  margin: 0 !important;
}
