.flexCheckboxItems {
  display: flex !important;
  justify-content: space-between !important;
}
.fontSize {
  font-size: 12px !important;
}
.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  height: 26px;
}
.tooltipAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.slotsContainer {
  width: 28rem !important;
  border-radius: 7px !important;
  border: 1px solid #ecedf2 !important;
  background-color: #ecedf2 !important;
  min-height: 42vh !important;
}
.slotsHeading {
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding-top: 5px !important;
}
.addSlotsButton:hover {
  background-color: #ecedf2 !important;
}
.addSlotHeading {
  color: #de1186 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}
.slotsCard {
  border-radius: 5px !important;
  background-color: #fff !important ;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.05) !important;
}
.slotsTextStyle {
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 10px !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  background-color: #de1186 !important;
}
.backBtn {
  height: 44px;
  color: #de1186 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  border: 1px solid #de1186 !important;
  background-color: #ffffff !important;
}
.backBtn:hover {
  background-color: #de1186 !important;
  color: #ffffff !important;
}
.backBtnDisable {
  height: 44px;
  color: #eeb0d3 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  border: 1px solid #eeb0d3 !important;
  background-color: #ffffff !important;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
.modalBox {
  width: 35rem !important;
  background-color: #ffffff;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  border-radius: 10px !important;
  padding: 15px 25px !important;
}
.cancelTextStyle {
  color: #2e1c41 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.checkboxAlign {
  padding-left: 0 !important;
}
