.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.tableBodyStyleDesignation {
  color: #484748 !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
.individualChipStyle {
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  font-size: 0.6875rem !important;
  border-radius: 22px !important;
  border: 1px solid #de1186 !important;
  background: #fff !important;
  color: #de1186 !important;
  font-weight: 500;
  justify-content: center;
  margin: 1px !important;
  height: 19px !important;
}

.addBookingStyle {
  background-color: #fff !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #de1186 !important;
  border-radius: 5px !important;
  border: 1px dotted #de1186 !important;
  width: 120px !important;
  height: 22px !important;
}
.notAvailableStyle {
  background-color: #ffb5b5 !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ce5959 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.holidayBadgeStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #3087e9 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.availableSlotStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #05b14e !important;
  border-radius: 5px !important;
  height: 22px !important;
  margin: 1px !important;
  width: 120px;
}
.bookingsBadgeStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #ffaf00 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.notProvidedSlotStyle {
  border-radius: 5px !important;
  background: #eff0f5 !important;
  color: #948b9e !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  height: 22px !important;
  width: 120px;
}
.avatarStyle {
  width: 26.325px !important;
  height: 26.291px !important;
  cursor: pointer !important;
}

.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
.tooltipAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.tableHead {
  line-height: 21px !important;
}
.tableRow {
  height: 41px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
.coordinatorsHeading {
  color: #948b9e !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: "flex" !important;
  align-items: "center" !important;
  font-weight: "bold" !important;
  padding-top: 5px !important;
  padding-right: 10px !important;
}
.coordinatorsWrap {
  color: #948b9e !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: "flex" !important;
  align-items: "center" !important;
  font-weight: "bold" !important;
  display: "flex" !important;
  flex-wrap: "wrap" !important;
}
.unavailableStyle {
  font-size: 12px !important;
  font-family: Poppins !important;
  font-weight: 400 !important;
}
.weekendAvailabilityHeadingStyle {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.weekendAvailabilityStyle {
  padding: 30px 24px 0px !important;
  margin: 5px !important;
}
.hideVerticalStyle {
  margin-bottom: 5px !important;
  flex-grow: 1 !important;
  overflow-y: hidden !important;
}
.badgeSlotsStyle {
  border-radius: 5px !important;
  width: 90rem !important;
  height: 2.5rem !important;
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
}
.badgeNotSlotsStyle {
  border-radius: 5px !important;
  width: 90rem !important;
  height: 2.5rem !important;
  color: #de1186 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border: 1px solid #de1186 !important;
  background-color: #fff !important;
}
.btnStyle {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  margin-bottom: 5px !important;
}
.slotsWrapStyle {
  flex-grow: 1;
  overflow-y: hidden;
  border-radius: 7px;
  border: 1px solid #ecedf2;
  background-color: #f8f8f8;
}
.tableOpenScroll {
  position: absolute !important;
  left: 16.5%;
  right: 0;
  max-width: 50.5%;
  max-height: calc(100vh - 205px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.tableCloseScroll {
  position: absolute !important;
  left: 6.5%;
  right: 0;
  max-width: 92.5%;
  max-height: calc(100vh - 205px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.column1headerFreeze {
  position: sticky !important;
  left: 0 !important;
  z-index: 100 !important;
  background-color: white !important;
}
.column1bodyFreeze {
  position: sticky !important;
  left: 0 !important;
  z-index: 70 !important;
  background-color: white !important;
}
.column2headerFreeze {
  position: sticky !important;
  left: 217px !important;
  z-index: 90 !important;
  background-color: white !important;
}
.column2bodyFreeze {
  position: sticky !important;
  left: 217px !important;
  z-index: 50 !important;
  background-color: white !important;
}
