.fontFamily {
  font-family: Poppins !important;
}
.slotCardIconBackground {
  text-align: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 15px;
}
.card {
  padding: 7px 15px !important;
}
.dropdown {
  min-width: 180px;
}
.noBorder {
  border: none !important;
}
.border {
  border-radius: 10px !important;
}
.shadow {
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.05) !important;
}
.active {
  border-bottom: 5px solid #de1186 !important;
}
.link {
  text-decoration: none;
}
.panelCardIconBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: blue;
}
.table {
  margin: auto !important;
  height: 400px !important;
}
.pagination {
  margin: auto !important;
}
.hoverDiv {
  cursor: pointer !important;
}
.chipClass {
  width: 10rem;
  margin: 2px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  align-items: center !important;
  color: #3e2482 !important;
  background-color: #ffffff !important;
  border-radius: 5px !important;
  border: 1px solid #3e2482 !important;
  border-radius: 8px !important;
}
.searchBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.cancelBtn:hover {
  background: white !important;
  color: #de1186 !important;
}
.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
