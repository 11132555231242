.boxContainer {
  display: flex !important;
  align-items: center !important;
  height: 95% !important;
  width: 95% !important;
  justify-content: center !important;
  background-color: rgb(250, 250, 250);
  flex-direction: column;
  border-radius: 10px;
  padding: 1rem !important;
  gap: 30px;
}
.noData {
  color: #2e1c41 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.BackBtn {
  display: flex !important;
  border: 1px solid #c33381 !important;
  background-color: #fff !important;
  width: 175px !important;
  height: 45px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
}
.BackBtn:disabled {
  display: flex !important;
  width: 175px !important;
  height: 45px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  font-weight: 700 !important;
  color: #eeb0d3 !important;
  background-color: #ffffff !important;
  border: 1px solid #eeb0d3 !important;
}
.btnText {
  color: #de1186 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}
.disableText {
  color: #eeb0d3 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}
