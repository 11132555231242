.weeklyViewDate {
  color: #2e1c41 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.switchStyle {
  font-family: Poppins !important;
  color: #2e1c41 !important;
  font-size: 14px !important;
}
.markAvailabilityButtonStyle {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 14px !important;
  border-radius: 5px !important;
}
.markAvailabilityButtonStyle:hover {
  background: #de1186 !important;
}
.markAvailabilityButtonStyle:disabled {
  border: 1px solid #eeb0d3 !important;
  border-radius: 5px !important;
  background-color: #f8f8f9 !important ;
  color: #eeb0d3 !important;
}
.iconStyle {
  height: 25px !important;
  width: 25px !important;
  color: #de1186 !important;
}
.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.column1headerFreeze {
  position: sticky !important;
  left: 0 !important;
  z-index: 100 !important;
  background-color: white !important;
}
.column1bodyFreeze {
  position: sticky !important;
  left: 0 !important;
  z-index: 70 !important;
  background-color: white !important;
}
.column2headerFreeze {
  position: sticky !important;
  left: 217px !important;
  z-index: 90 !important;
  background-color: white !important;
}
.column2bodyFreeze {
  position: sticky !important;
  left: 217px !important;
  z-index: 50 !important;
  background-color: white !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.tableBodyStyleDesignation {
  color: #484748 !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
.individualChipStyle {
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  font-size: 0.6875rem !important;
  border-radius: 22px !important;
  border: 1px solid #de1186 !important;
  background: #fff !important;
  color: #de1186 !important;
  font-weight: 500;
  justify-content: center;
  margin: 1px !important;
  height: 19px !important;
}

.addBookingStyle {
  display: flex;
  justify-content: center;
  background-color: #fff !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #de1186 !important;
  border-radius: 5px !important;
  border: 1px dotted #de1186 !important;
  width: 120px !important;
  height: 22px !important;
}
.notAvailableStyle {
  background-color: #ffb5b5 !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ce5959 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.holidayBadgeStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #338d9f !important;
  background-color: #bcecf6 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.weekendDriveBadgeStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #3087e9 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.weekendDriveBadgeStyle:hover {
  cursor: pointer !important;
}
.availableSlotStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #05b14e !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px;
}
.bookingsBadgeStyle {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #ffaf00 !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 120px !important;
}
.notProvidedSlotStyle {
  border-radius: 5px !important;
  background: #eff0f5 !important;
  color: #948b9e !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  height: 22px !important;
  width: 120px;
}
.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
.tooltipAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.tableHead {
  line-height: 21px !important;
}
.tableRow {
  height: 41px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.modalBox {
  background-color: #ffffff;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  border-radius: 10px !important;
  padding: 15px 25px !important;
  width: 33rem !important;
}
.modalBody {
  max-height: 400px !important;
  overflow-y: auto !important;
}
.suspensionHeading {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 10px;
  color: #2e1c41 !important;
  margin-right: 7px !important;
}
.addUserRoleButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addComment {
  color: #948b9e !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.addUserRoleButtonStyle:hover {
  background: #de1186 !important;
  color: #fff;
}
.tempSuspTitle {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #948b9e !important;
  margin-bottom: 6px !important;
}
.tempSuspBookingsTitle {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #2e1c41 !important;
  margin-bottom: 6px !important;
}
.currentWeekBtn {
  font-weight: 700 !important;
  font-size: 11px !important;
  align-items: center !important;
  border: 1px solid #de1186 !important;
  border-radius: 5px !important;
  background-color: #f8f8f9 !important ;
  color: #de1186 !important;
  padding: 10px !important;
}
.currentWeekBtnDisable {
  font-weight: 700 !important;
  font-size: 11px !important;
  align-items: center !important;
  border: 1px solid #eeb0d3 !important;
  border-radius: 5px !important;
  background-color: #f8f8f9 !important ;
  color: #eeb0d3 !important;
  padding: 10px !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
.bookingOverlayHeading {
  color: #000;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.bookingOverlaySubHeading {
  color: #676767 !important;
  font-size: 11px !important;
}
.bookingOverlayItemHeading {
  color: #2e1c41 !important;
  font-size: 12px !important;
  text-align: left !important;
}
.bookingOverlayItemSubHeading {
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: left;
  margin: 0px 8px !important;
}
.Tabs {
  width: 215px !important;
  height: 30px !important;
  background-color: #eff0f5 !important;
  color: #a199aa !important;
  border: 0.25px solid #dee2e6 !important;
  border-radius: 19.5px !important;
}
.tabshr {
  width: 220px !important;
  height: 30px !important;
  background-color: #eff0f5 !important;
  color: #a199aa !important;
  border: 0.25px solid #dee2e6 !important;
  border-radius: 19.5px !important;
}
.Tab {
  line-height: 18px !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.buttonHeight {
  height: 40px !important;
}
.avatarStyle {
  width: 26.325px !important;
  height: 26.291px !important;
  cursor: pointer !important;
}
.tableOpenScroll {
  position: absolute !important;
  left: 16.5%;
  right: 0;
  max-width: 50.5%;
  max-height: calc(100vh - 205px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.tableCloseScroll {
  position: absolute !important;
  left: 6.5%;
  right: 0;
  max-width: 92.5%;
  max-height: calc(100vh - 205px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.hideScroll {
  overflow: hidden !important;
}
