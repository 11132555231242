body {
  margin: 0;
  background: #F8F8F9;
}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(161, 157, 157);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}