.createBookingContainer {
  width: 500px !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  justify-content: space-between;
  overflow-x: hidden !important;
}
.headingStyle {
  color: #2e1c41;
  font-weight: 500;
}

.nextBtnStyle {
  height: 4.5vh;
}
.nextBtnStyle:hover {
  background: #de1186 !important;
}
.nextBtnStyle:disabled {
  background: #eeb0d3 !important;
  color: #fff !important;
}
.backBtnStyle {
  height: 4.5vh;
}
.backBtnStyle:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.backBtnStyle:disabled {
  color: #eeb0d3 !important;
  border-color: #eeb0d3 !important;
}
.uploadButtonStyle {
  height: 2.5rem !important ;
  padding: 0px 10px;
  border-radius: 2px;
  border: 1px dotted #de1186 !important;
  color: #2e1c41 !important;
}
.closeIconStyle {
  margin-top: 2px;
  height: 13px;
  width: 15px;
  cursor: pointer;
}
.eventCheckBox {
  background-color: #fff !important;
  color: #2e1c41 !important;
}
.detailsHeading {
  color: #2e1c41 !important;
}
.paperStyle {
  padding: 0 !important;
  border-radius: 7px !important;
  border: 1px solid #ecedf2 !important;
  background: #f8f8f8 !important;
  box-shadow: none;
}
.stepStyle {
  padding: 0 !important;
}
[class="MuiStepLabel-label"] {
  color: #de1186 !important;
}
.stepLabelStyle {
  color: #de1186 !important;
}
.phoneInputStyle {
  height: 4rem;
  border-radius: 4px;
  border: 1px solid #ecedf2;
}
.notAvailableStyle {
  background-color: #ffb5b5 !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  align-items: center !important;
  color: #ce5959 !important;
  border-radius: 5px !important;
  height: 22px !important;
}
.availableSlotStyle {
  font-weight: 500 !important;
  font-size: 10px !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #05b14e !important;
  border-radius: 5px !important;
  height: 22px !important;
}
.bookingsBadgeStyle {
  font-weight: 500 !important;
  font-size: 10px !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #ffaf00 !important;
  border-radius: 5px !important;
  height: 22px !important;
}
.notProvidedSlotStyle {
  border-radius: 5px !important;
  background: #eff0f5 !important;
  color: #948b9e !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  height: 22px !important;
}
.timeConflictingContainer {
  height: 100% !important;
}
.timeConflictingStyle {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.popupScreenHeading {
  color: #2e1c41 !important;
  font-size: 19px !important;
  font-weight: 500 !important;
  margin: 30px 8px 8px 8px !important;
  text-align: center !important;
}
.popupScreenSubHeading {
  color: #2e1c41 !important;
  font-size: 17px !important;
  text-align: center !important;
}
.interviewStatusNaOrRsPopup {
  height: 100% !important;
}
.interviewStatusNaOrRsPopupSubContainer {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.warningPopupContainer {
  background-color: #fef7e8;
  border: 1px solid #f3e0bc;
  border-radius: 5px;
}
.warningPopupContainerForNaStatus {
  background-color: #FAEAEA	;
  border: 1px solid #EEB0D3;
  border-radius: 5px;
}
.warningPopupTitle {
  color: #2e1c41 !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-top: 2px !important;
}
.warningPopupSubTitle {
  color: #2e1c41 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  margin-top: 4px !important;
}
