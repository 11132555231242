.markAvailabilityContainer {
  width: 455px !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  justify-content: space-between !important;
  overflow-x: hidden !important;
}
.heading {
  color: #2e1c41 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.dateLabel {
  color: #948b9e !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}
.marginTop {
  margin-top: 10px !important;
}
.daySlotWrap {
  border-radius: 7px !important;
  border: 1px solid #ecedf2 !important;
  background-color: #ecedf2 !important;
  margin: 8px 0 !important;
}
.timeText {
  color: #948b9e !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  padding-right: 5px !important;
}
.availableText {
  color: #2e1c41 !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  padding-top: 3px !important;
}
.weekdaysText {
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.unavailableText {
  color: #2e1c41 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.timeInput {
  background-color: #fff !important;
}
.saveBtn {
  height: 44px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.saveBtn:disabled {
  background-color: #eeb0d3 !important;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
.hideVerticalStyle {
  flex-grow: 1 !important;
  overflow-y: hidden !important;
}
.daysWrap {
  height: 52.735rem !important;
  max-height: 51.6rem !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.alertstyle {
  padding: 0.5px 3px !important;
  font-size: 12px !important;
}
