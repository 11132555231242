.modalBox {
  background-color: #ffffff;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  max-width: 464px !important;
  border-radius: 10px !important;
  height:37rem;
  width:31.25rem;
}
.paddingmodalBox {
  padding: 20px !important;
}
.selectAllLabel{
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.reminderListHeading {
  color: #2e1c41 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-top: 1.3;
}
.designationList {
  color: #2e1c41 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.switchStyle {
  font-family: Poppins !important;
  color: #2e1c41 !important;
  font-size: 14px !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
  cursor: pointer;
}
.selectBtn {
  color: #de1186 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  border:1px solid #de1186 !important;
  background-color: #ffffff !important;
  cursor: pointer;
}
.selectBtn:disabled {
  color: #eeb0d3 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  border:1px solid #eeb0d3 !important;
  background-color: #ffffff !important;
}
.saveBtn:disabled {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #eeb0d3 !important;
  cursor: pointer;
}
.checkbox{
  align-items: flex-start !important;
}