.avatarStyle {
  width: 26.325px !important;
  height: 26.291px !important;
  cursor: pointer !important;
}

.tableBodyStyleDesignation {
  color: #484748 !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}