.fontFamily {
  font-family: Poppins !important;
}
.addBtnStyle {
  color: #fff !important;
  background: #de1186 !important;
  cursor: pointer;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}

.addTagStyle {
  color: #fff !important;
  background: #de1186 !important;
  cursor: pointer;
  display: inline-flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}

.addTagButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addTagButtonStyle:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.addTagButtonStyle:disabled {
  background: #eeb0d3 !important;
  color: #fff !important;
}
.sectionHeading {
  margin-left: 5px !important;
  color: #2e1c41;
  font-size: 20px !important;
  font-weight: 400 !important;
}
.sectionStyle {
  overflow: auto;
  margin-top: 1.5rem !important;
  height: 139px;
  border-radius: 12px;
  border: 1px solid #eff0f5;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.05);
}
.eachLink {
  font-size: 14px !important;
}
.inputStyle {
  color: #948b9e !important;
}
