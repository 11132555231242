.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tagBadgeStyle {
  color: #3e2b82;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 22px;
  border: 1px solid #3e2b82;
  padding: 2px 7px;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.buttonGridStyle {
  height: 100% !important;
}
.buttonStyle {
  font-weight: 700 !important;
  height: -webkit-fill-available;
  margin: 16px 0px 8px 0px !important;
}

.addTagButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addTagButtonStyle:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.addTagButtonStyle:disabled {
  background: #eeb0d3 !important;
  color: #fff !important;
}
.tableHeight {
  max-height: 68vh !important;
}
