.layout {
  flex-grow: 1;
  height: calc(100vh - 156px);
  padding: 24px 24px 16px 0px;
  margin-left: 7% !important;
}
.openlayout {
  flex-grow: 1;
  height: calc(100vh - 156px);
  padding: 24px 24px 16px 16.5%;
}
