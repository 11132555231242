.loginContainer {
  display: flex !important;
  align-items: center !important;
  height: 100vh !important;
  justify-content: center;
  background-color: rgb(250, 250, 250);
  flex-direction: column;
  border-radius: 10px;
  padding: 1rem !important;
}

.loginCard {
  max-width: 400;
  backdrop-filter: blur(15px) !important;
  padding: 2rem 4.5rem !important;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 0px !important;
  border-radius: 1rem !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.img {
  width: 100px;
  height: 100px;
}

.loginHeading {
  background-color: "white";
  color: #212529;
  text-align: center;
  padding: 10px;
  font-weight: 500 !important;
}

.loginSubtitle {
  background-color: white;
  padding: 20px;
}

.loginButton {
  height: 45px !important;
  margin-top: 1rem !important;
  padding: 10px 4rem !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}

.loginButton:hover {
  background: #de1186 !important;
  color: #fff;
}
