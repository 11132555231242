.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.saveBtnDisable{
  height: 44px;
  color:#FFFFFF !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color:#EEB0D3 !important;
}
.flexCheckboxItems{
  display: flex !important;
  justify-content: space-between !important;
}
.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
.fontSize {
  font-size: 12px !important;
}
.searchBtn:hover{
  background: #de1186 !important;
  color: #fff;
}
  .addTagStyle {
    color: #fff !important;
    background: #de1186 !important;
    cursor: pointer;
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    }