.img {
  width: 75px;
  height: 65px;
}

.Menu {
  margin-top: -0.9rem;
}

.MenuList {
  width: 220px;
  border-radius: 0;
}

.BoxMenuList {
  width: 231px;
  border-radius: 0;
}

.DrawerMenuList {
  width: 232px !important;
  padding: 15px !important;
  border-radius: 8px !important;
  margin-top: 45px !important;
}

.MenuIcon {
  color: #948b9e;
}

.MenuListText {
  font-size: 14px !important;
  color: #2e1c41;
  font-weight: 500 !important;
}

.searchBarForm {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #d3cfd6;
  background-color: #fff;
  box-shadow: none !important;
}

.searchIconStyle {
  padding: 10px;
}

.searchBarStyle {
  flex: 1;
  font-size: 14px;
}

.avatarStyle {
  margin: 2px 15px;
}

.appdrawer {
  width: 50px !important;
  height: 50px !important;
}

.drawericons {
  width: 50px !important;
  height: 50px !important;
  background-color: #EFF0F5 !important;
  border-radius: 50% !important;
}

.drawericons:hover {
  cursor: pointer !important;
  background-color: #de1186 !important;
}

.appdrawer:hover {
  cursor: pointer !important;
}

.modalBox {
  background-color: #ffffff;
  position: absolute !important;
  top: 20% !important;
  left: 80% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  padding: 15px 25px !important;
}

.modalBody {
  max-height: 400px !important;
  overflow-y: auto !important;
}

.drawerText {
  color: #2e1c41 !important;
  font-size: .625rem !important;
  font-weight: 400 !important;
}