.breadcrumbTextStyle {
  color: #948b9e !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
}
.breadcrumbActive {
  color: #2e1c41 !important;
}
.buttonStyle {
  display: inline-flex;
  height: 50px;
  width: 220px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}
.buttonStyle:hover {
  color: #fff;
  background-color: #de1186 !important;
}
.chartStyle {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 37.5rem;
  width: 30vw;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.05);
}
.chartLabelStyle {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #2e1c41;
  margin-top: 18px !important;
}
.outerBoxStyle {
  display: flex;
  border-radius: 10px;
  border: 1px solid #eff0f5;
  width: 16vw;
  height: 4.9vw;
  overflow: visible;
  flex-shrink: 0;
}
.innerBoxStyle {
  display: flex !important;
  justify-content: center !important;
  width: 3vw !important;
  height: 3vw !important;
  border-radius: 10px;
  margin: 10px 0px 0px 10px;
  padding: 0.625vw;
}
.individualBoxBg {
  background-color: #648fcb;
}
.pairedBoxBg {
  background-color: #83ccee;
}
.boxTextStyle {
  font-size: 0.75vw !important;
  word-wrap: break-word;
  color: #948b9e !important;
  margin: 10px 0px 0px 0px !important;
}
.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  border-radius: 12px !important;
  margin: 16px !important;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.13) !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 62vh !important;
}
.countTextStyle {
  font-size: 1vw !important;
}
.pieGraphContainer {
  width: 22vw !important;
  height: 22vw !important;
}
.graphDetailContainer {
  margin-top: 9vw !important;
  margin-left: 2.5vw !important;
  margin-right: 1.5vw !important;
}
.tableHeadingStyle {
  width: 25% !important;
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tableBodyStyle {
  width: 25% !important;
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.sumTableRowStyle {
  font-weight: 700 !important;
}
.cursorPointer {
  cursor: pointer;
}
.bookingDataModal {
  height: 24rem !important;
  width: 28rem !important;
  min-height: 30vh !important;
  max-height: 100vh !important;
  z-index: 1300 !important;
  border-radius: 10px !important;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.13) !important;
}
.panelistDataModal {
  height: 21rem !important;
  width: 28rem !important;
  min-height: 30vh !important;
  max-height: 100vh !important;
  z-index: 1300 !important;
  border-radius: 10px !important;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.13) !important;
}
.addTagButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addTagButtonStyle:hover {
  background: #de1186 !important;
  color: #fff;
}
.dialogTitle {
  padding: 30px 24px 0px !important;
}
.dialogActions {
  padding: 0px 24px 30px !important;
}
