.breadcrumbs {
  font-weight: 500;
  font-size: 11px;
  line-height: 16.5px;
  margin-top: 22px !important;
  margin-bottom: 20px !important;
}
.pairingChipStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #fff !important;
  border: 1px solid #3e2b82 !important;
  color: #3e2b82 !important;
  font-weight: 500;
}
.individualChipStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  border: 1px solid #de1186 !important;
  background: #fff !important;
  color: #de1186 !important;
  font-weight: 500;
  justify-content: center;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.tableBodyStyleDesignation {
  color: #484748 !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
.Tabs {
  width: 215px !important;
  height: 30px !important;
  background-color: #eff0f5 !important;
  color: #a199aa !important;
  border: 0.25px solid #dee2e6 !important;
  border-radius: 19.5px !important;
}
.tabshr {
  width: 430px !important;
  height: 30px !important;
  background-color: #eff0f5 !important;
  color: #a199aa !important;
  border: 0.25px solid #dee2e6 !important;
  border-radius: 19.5px !important;
}
.Tab {
  line-height: 18px !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.pointer {
  cursor: pointer !important;
}
.textAlign {
  text-align: right !important;
}
.fontWeight {
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
}
.fontSize {
  font-size: 12px;
}
.icons {
  padding-left: 32.4px;
}
.addIcon {
  padding-left: 12.2px;
  padding-right: 12.2px;
  border: 1.5px;
}
.paddingRight {
  padding-right: 25.4px !important;
}
.paddingTop {
  padding-top: 1rem !important;
}
.filterContainer {
  background-color: white;
  padding: 20px 21px 27.4px 20px;
  margin-top: 24px;
  border-radius: 12px;
}

.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  height: 26px;
}
.tableContainer {
  max-height: 100%;
  margin-bottom: 44px !important;
  border-radius: 10px;
  padding-left: 27px;
  padding-right: 27px;
}
.tableHead {
  line-height: 21px;
}
.tableRow {
  height: 41px;
  font-weight: 500;
  font-size: 14px;
}

.tempUnavail {
  padding-left: 16.2px;
  padding-right: 16.2px;
  border: 1.5px;
}
.tagstextBadge {
  padding: 2px 7px;
  align-items: center;
  border-radius: 22px !important;
  justify-content: center;
  color: #ffffff !important;
  font-size: 10px !important;
  background-color: #7e718c !important;
  font-weight: 500 !important;
  line-height: 15px;
  font-size: 10px !important;
}
.actionIcons {
  width: 24px !important;
  height: 24px !important;
  color: #de1186;
}
.badge {
  background-color: #dcebff;
  border-radius: 2px;
  padding: 2px 7px;
  color: #264e85;
  line-height: 15px;
  font-weight: 500;
  font-size: 10px !important;
}

.marginTop {
  margin-top: 2px !important;
}
.marginBottom {
  margin-bottom: 5px !important;
}
.tagsWrap {
  gap: 3px !important;
}
.tagsBadge {
  border: 1px solid #3e2b82;
  border-radius: 2px;
  padding: 2px 7px;
  color: #264e85;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
.addBtn {
  border-radius: 3px !important;
  color: #de1186 !important;
  padding: 2px 7px;
  text-align: center;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  background-color: white !important;
}
.modalSizeDate {
  width: 400px;
}
.modalSizeNotes {
  width: 566px !important;
}
.paddingmodalBox {
  padding: 30px !important;
}
.paddingdateBox {
  padding: 10px 20px 30px 30px !important;
}
.modalBox {
  background-color: #ffffff;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  border-radius: 10px !important;
}
.modalBody {
  max-height: 400px !important;
  overflow-y: auto !important;
}
.suspensionHeading {
  font-weight: 500 !important;
  padding: 2px 7px !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 10px;
  color: #2e1c41 !important;
}
.notesHeading {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 10px;
  color: #2e1c41 !important;
}
.cardWrap {
  padding: 16px;
  border-radius: 7px;
  border: 1px solid #ecedf2;
  background-color: #f8f8f8 !important;
}
.userComment {
  color: #2e1c41;
  font-weight: 600;
  line-height: 18px;
}
.commentCreated {
  color: #948b9e;
  font-weight: 400;
  line-height: 18px;
}
.comment {
  color: #2e1c41;
  font-weight: 400;
  line-height: 18px;
}
.no_comment {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 5px;
}
.addComment {
  color: #948b9e !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.fontSize {
  font-size: 12px !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
.pagination {
  padding-top: 21px;
  padding-bottom: 20px;
  width: 345.27px;
  height: 40px;
}
.addTagButtonStyle {
  display: flex;
  align-items: center;
  height: 32px !important;
  width: 30px !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addTagButtonStyle:hover {
  background: #de1186 !important;
  color: #fff;
}
.addUserRoleButtonStyle {
  display: flex;
  align-items: center;
  height: 2.75rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}
.addUserRoleButtonStyle:hover {
  background: #de1186 !important;
  color: #fff;
}
.suspendReason {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.sortIconStyle {
  margin-left: 8px;
  color: #948b9e;
}
.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
.roleInputStyle {
  border: 1px solid #ecedf2;
  color: #2e1c41 !important ;
}
.addTagStyle {
  color: #fff !important;
  background: #de1186 !important;
  cursor: pointer;
  display: inline-flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}
.tooltipAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.activeRolesStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #fff !important;
  border: 1px solid #3e2b82 !important;
  color: #3e2b82 !important;
  font-weight: 500;
}
.adminActiveRolesStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #fff !important;
  border: 1px solid #de1186 !important;
  color: #de1186 !important;
  font-weight: 500;
}
.adminActiveManagerRolesStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #fff !important;
  border: 1px solid #ffaf00 !important;
  color: #ffaf00 !important;
  font-weight: 500;
}
.headingModal {
  font-size: 16px !important;
  color: #2e1c41 !important;
  font-weight: 500 !important;
}
.selectText {
  font-size: 12px !important;
  color: #2e1c41 !important;
  font-weight: 400 !important;
}
.saveTagBtn {
  font-size: 10px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  align-items: center;
  height: 2.2rem !important;
  border-radius: 7px !important;
}
.avatarStyle {
  width: 26.325px !important;
  height: 26.291px !important;
  cursor: pointer !important;
}
.cancelBtn {
  background-color: white !important;
  color: #de1186 !important;
  height: 44px;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
}
.cancelBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.searchBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.searchBtn:disabled {
  background-color: #eeb0d3 !important;
  color: #fff !important;
}
.imgCircle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.part-of-panel {
  background-color: #eaedc9 !important;
}
.suspended {
  background-color: #ffbab6 !important;
}
.filtersHeading {
  color: #2e1c41 !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.legendIcon {
  margin-right: 2px !important;
  color: #f68cc3 !important;
  cursor: pointer !important;
  margin-top: 2px !important;
}
