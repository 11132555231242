.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-bottom: none !important;
  padding: 0.5rem 1rem !important;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-bottom: none !important;
  padding: 0.5rem 1rem !important;
}
.pairingChipStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #ffdaef !important;
  border: 1px solid #3e2b82 !important;
  color: #de1186 !important;
  font-weight: 500;
}
.individualChipStyle {
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  border: 1px solid #3e2b82 !important;
  background: #fff !important;
  color: #3e2b82 !important;
  font-weight: 500;
  justify-content: center;
  margin: 1px !important;
  height: 19px !important;
}
.panelViewContainer {
  margin-top: 10px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.05);
}
.colorsText {
  padding-left: 5px !important;
  color: #948b9e;
}
