.addTagButtonStyle {
    display: flex;
    align-items: center;
    height: 2.75rem !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
  }
  .addTagButtonStyle:hover {
    background: #de1186 !important;
    color: #fff;
  }