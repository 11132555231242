.createDriveButtonStyle {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 14px !important;
  border-radius: 5px !important;
  height: 40px !important;
}
.createDriveButtonStyle:hover {
  background: #de1186 !important;
}
.tableContainer {
  max-height: 65vh !important;
  margin-bottom: 44px;
  border-radius: 10px;
  padding-left: 27px;
  padding-right: 27px !important;
  width: 97% !important;
}
.modalBox {
  background-color: #ffffff;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-width: 1px;
  border-radius: 10px !important;
}
.suspensionHeading {
  font-weight: 500 !important;
  padding: 2px 7px !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 10px;
  color: #2e1c41 !important;
}
.paddingmodalBox {
  padding: 30px !important;
}
.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.pairingChipStyle {
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  background: #fff !important;
  border: 1px solid #3e2b82 !important;
  color: #3e2b82 !important;
  font-weight: 500;
  margin: 1px !important;
}
.individualChipStyle {
  margin: 1px !important;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem !important;
  border-radius: 22px !important;
  border: 1px solid #de1186 !important;
  background: #fff !important;
  color: #de1186 !important;
  font-weight: 500;
  justify-content: center;
}
.pointer {
  cursor: pointer !important;
}
.tooltipAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.buttonHeight {
  height: 40px !important;
}
.filteredStyles {
  cursor: pointer !important;
  background-color: #de1186 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.unfilteredStyles {
  cursor: pointer !important;
}
.searchBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
