.filterContainer {
  width: 400px !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  justify-content: space-between;
  overflow-x: hidden !important;
}
.fontSize {
  font-size: 12px !important;
}
.filtersHeading {
  color: #2e1c41 !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  height: 26px;
}
.searchBtn:hover {
  background: #de1186 !important;
  color: #fff !important;
}
.searchBtn:disabled {
  background-color: #eeb0d3 !important;
  color: #fff !important;
}
