.tableStyle {
  width: 100% !important;
  overflow: hidden !important;
  box-shadow: 0px 6px 30px 0px #0000000d !important;
  border: 1px solid #eff0f5 !important;
}
.tableHeight {
  max-height: 71vh !important;
}
.tableHeadingStyle {
  color: #2e1c41 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.tableBodyStyle {
  color: #2e1c41 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.saveBtn {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  background-color: #de1186 !important;
}
.addTagStyle {
  color: #fff !important;
  background: #de1186 !important;
  cursor: pointer;
  display: inline-flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}
.saveBtnDisable {
  height: 44px;
  color: #ffffff !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  background-color: #eeb0d3 !important;
}
