.customDrawer {
  flex-shrink: 0;
  box-sizing: border-box;
}
.listItemStyle {
  background-color: #eff0f5 !important;
  border-radius: 5px;
  color: #de1186 !important;
}
.listItemIconStyle {
  color: #de1186 !important;
}
